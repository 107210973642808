@mixin primary-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}

@mixin secondary-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.04em;
}
